.checkout-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    gap: 50px;
}

.cart-item-wrapper {
    width: 400px;
    margin-right: 20px;
}

.checkout-wrapper {
    width: 600px;
    min-width: 300px;
}

@media (max-width: 600px) {
    .checkout-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px;
        padding: 0px;
        gap: 0px;
    }

    .cart-item-wrapper {
        width: 300px;
        margin-right: 0px;
    }

    .checkout-wrapper {
        width: 300px;
        min-width: 300px;
    }

    .cart-item-wrapper {
        order: 1;
    }

    .checkout-wrapper {
        order: 2;
    }
}