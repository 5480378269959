.nav-bar-logo {
    width: 180px;
}  

.shopping-cart {
    font-size: 22px;
    margin-right: 15px;
    color: #708090;
}

.custom-menu-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 960px) {
    .p-menubar-start {
        order: 1; /* Keeps the start section at the beginning */
    }

    .p-menubar-button {
        margin-right: 10px;
    }

    /* At lower screen reduce the space to the right */
    .text-logo {
        margin-right: 30px !important;
        margin-left: 0 !important;
    }
    
    .p-menubar-end {
        order: 2; /* Moves the end section (menu icon) to the far right */
        margin-left: auto; /* Pushes the menu icon to the far right */
    }
}

.text-logo {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 60px;
    margin-left: 25px;
}

.logo-part1 {
    font-weight: 700;
    font-size: 22px;
    color: #333; 
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;
}

.logo-part2 {
    font-weight: 300;
    font-size: 22px;
    color: #666;
    font-family: 'Montserrat', sans-serif;
}