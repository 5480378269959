.p-galleria-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-galleria-item-prev,
.p-galleria-item-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: darkgray;
    cursor: pointer;
}

.p-galleria-item-prev svg,
.p-galleria-item-next svg {
    width: 12px; /* Adjust width for larger screens */
    color: darkgray;
    cursor: pointer !important;
}

/* Control size of main image */
.p-galleria-item {
    width: 300px;
}

/* set backgroun of thumbnail to white */
.p-galleria-thumbnail-container {
    background-color: #ffffff;
}

/* Thumbnail items should show in row flex */
.p-galleria-thumbnail-item {
    max-width: fit-content;
}

.p-galleria-thumbnail-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Limit the thumbnail size */
.thumbnail-item {
    padding: 0 5px;
    max-height: 100px;
}

/* Adjust for mobile view */
@media (max-width: 640px) {
    .p-galleria-item-prev {
        left: -20px; /* Closer to the image on smaller screens */
    }

    .p-galleria-item-next {
        right: -20px;
    }
}

/* Adjust for desktop view */
@media (min-width: 1900px) {
    .p-galleria-item-prev {
        left: 100px; /* Further out on larger screens */
    }

    .p-galleria-item-next {
        right: 100px;
    }
}