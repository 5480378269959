.testimonial-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
    gap: 10px;
    margin-bottom: 40px;
}

.testimonial-icon {
    font-size: 30px;
}

@media (max-width: 760px) {
    .testimonial-container {
        flex-direction: column;
    }
}
