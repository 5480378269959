.about-us-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.banner-text {
    font-size: 20px;
    background-color: black;
    margin-bottom: 24px;
    padding: 0px;
    padding: 8px 20px;
    padding-bottom: 10px;
    border-radius: 10px;
}

.our-story {
    padding: 0 30px;
}

.our-mission {
    padding: 0 30px;
}

@media (max-width: 500px) {
    .our-story {
        padding: 0 15px;
    }
    
    .our-mission {
        padding: 0 15px;
    }
}