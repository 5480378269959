.product-card {
    max-width: 15%;
    margin: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.product-card:hover {
    transform: translateY(-5px);           /* Lift effect on hover */
}

.product-card .p-card-body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

.product-card .p-card-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media (max-width: 400px) {
    .product-card .p-card-header {
        display: flex;
        justify-content: center;
        min-height: auto;
    }
}

.product-card .p-card-title {
    font-size: medium;
    margin: 0;
}

.product-card .p-card-subtitle {
    margin: 0;
}

.product-card .p-card-footer {
    padding: 0;
    padding-top: 5px;
    margin-bottom: 5px;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
    .product-card {
        max-width: 45%;  /* Adjusts the width on tablet-sized screens */
    }
}

@media (max-width: 480px) {
    .product-card {
        max-width: 90%;  /* Adjusts the width on mobile-sized screens */
    }
}