.p-carousel-prev {
    transform: translateY(-70px);  /* Move the button 10px up */
}
  
.p-carousel-next {
    transform: translateY(-70px);  /* Move the button 10px up */
}


.product-template-card {
    margin: 0px 10px;
    margin-bottom: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease;
}

.product-template-card .p-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 290px;
}

@media (max-width: 400px) {
    .product-template-card .p-card-header {
        min-height: auto;
    }
}

.product-template-card:hover {
    transform: translateY(-5px);           /* Lift effect on hover */
}

.product-template-card .p-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 130px;
}

.product-template-card .p-card-title {
    font-size: medium;
    margin-bottom: 0;
}

.product-template-card .p-card-footer {
    padding-top: 0;
}
