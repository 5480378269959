.background-container {
    position: relative;
    width: 100%;
    height: 48rem; /* Equivalent to h-96 */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.20); /* Black overlay with 50% opacity */
    z-index: 1; /* Places the overlay behind content */
}

.slider-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 180px;
    z-index: 2;
}

.slider-text {
    font-size: 1.5rem; /* Larger font size */
    font-weight: bold; /* Bold text */
    color: #ffffff; /* Adjust color as needed */
    text-align: center;
    margin: 0.5rem 0; /* Spacing between paragraphs */
    opacity: 0; /* Start invisible for fade-in effect */
    animation: fadeIn 0.8s ease-out forwards; /* Animation duration and effect */
}

/* Keyframes for the fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px); /* Slide up effect */
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}