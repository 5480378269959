.add-to-cart {
    padding: 10px 15px;
    font-size: small;
}

.quantity-selector {
    width: 90px;
    border: 1px solid grey;
}

@media (max-width: 500px) {
    .add-to-cart {
        min-width: 99px;
    }
}