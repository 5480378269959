.quantity-selector {
    width: 60px;
    height: 40px;
    border-radius: 10px;
}

.cart-quantity-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    margin-right: 10px;
}

.country-selector {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    margin-bottom: 8px;
}