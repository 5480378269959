.testimonial-card {
    border: 1px solid #E0E0E0;              /* Light border for the card */
    border-radius: 8px;                     /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* More pronounced shadow for depth */
    max-width: 500px !important;                       /* Control max width for readability */
    margin: 1rem auto;                      /* Center align with margin */
    padding: 1.5rem;                        /* Spacing within the card */
    background-color: #FFFFFF;              /* White background for the card */
    transition: transform 0.2s ease;        /* Subtle hover effect */
}

.testimonial-card:hover {
    transform: translateY(-5px);           /* Lift effect on hover */
}

.testimonial-icon {
    font-size: 2.5rem;                     /* Adjust icon size */
    color: #FF6347;                        /* Icon color */
    margin-bottom: 1rem;                   /* Spacing below icon */
}

@media (max-width: 600px) {
    .testimonial-card {
        padding: 1rem;                     /* Reduce padding on smaller screens */
        max-width: 90%;                     /* Ensure it fits well on mobile */
    }
}
