.contact-page-wrapper {
    gap: 10px;
    align-items: flex-start;
}

@media (max-width: 500px) {
    .left-section-container {
        min-width: 310px;
    }
    
    .right-section-container {
        min-width: 310px;
    }

    .contact-page-wrapper {
        align-items: center;
    }
}